.label, .badge {
    text-transform: uppercase;
    color: white;
    font-weight: bold;
    font-size: 10px;
    border-radius: 5px;
    height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: $green;
    padding: 5px 10px;
    overflow: hidden;

    &.status- {

        &100, &200, &300,  {
            background-color: $orange;
        }
        &350, &400, &450, &500 {
            background-color: $dark-red;
        }
        &550, &600, &650, &700 {
            background-color: $green;
        }
    }

    &-success {
        background-color: $green;
    }

    &-danger {
        background-color: $red;
    }
}

.label {
    width: 150px;
    white-space: pre-wrap;
    text-align: center;
    line-height: 1.3;
    height: auto;
}

.badge {
    width: 90px;
}