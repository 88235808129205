.app-header.navbar {
  position: fixed;
  z-index: $zindex-sticky;
  width: 100%;
  display: flex;
  flex-direction: row;
  height: $navbar-height;
  padding: 0;
  margin: 0;
  background-color: $navbar-bg;
  @include borders($navbar-border);

  .navbar-brand {
    display: inline-block;
    width: 50px;
    height: 50px;
    flex: 0 0 auto;
    border-radius: 15px;
    padding: $navbar-padding-y $navbar-padding-x;
    margin-right: 0;
    background-color: white;
    background-image: $navbar-brand-logo;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 30px 30px;
  }

  .navbar-brand-group {
    width: 230px;
    height: 80px;
    flex: 0 1 auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 15px;
    background-color: $secondary;
    color: white;
    -webkit-transition: all $layout-transition-speed ease-in-out;
    -moz-transition: all $layout-transition-speed ease-in-out;
    -ms-transition: all $layout-transition-speed ease-in-out;
    -o-transition: all $layout-transition-speed ease-in-out;
    transition: all $layout-transition-speed ease-in-out;
    overflow: hidden;
  }

  .navbar-brand-name {
    flex: 1 0 auto;
    width: 120px;
    margin-left: 15px;
  }

  .navbar-toggler {
    min-width: 50px;
    padding: $navbar-toggler-padding-y 0;

    &:hover .navbar-toggler-icon {
      background-image: $navbar-toggler-icon-hover;
    }
  }

  .navbar-toggler-icon {
    height: 23px;
    background-image: $navbar-toggler-icon;
  }

  .navbar-nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex: 1 0 auto;
    height: 100%;
    padding-left: 20px;

    &.accountBar {
      flex: 0 1 auto;

      .dropdown .nav-link i {
        width: 40px;
        height: 40px;
        border-radius: 20px;
        background-color: transparent;
        border: 1px solid $secondary;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .nav-item {
    position: relative;
    min-width: 50px;
    margin: 0 !important;
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    button {
      margin: 0 auto;
    }

    .nav-link {
      padding-top: 0;
      padding-bottom: 0;
      background: 0;
      border: 0;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      &.active {
        border-bottom: 2px solid $secondary;
        font-weight: 600;
      }
  

      .badge {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -16px;
        margin-left: 0;
      }

      > .img-avatar {
        height: $navbar-height - 20px;
        margin: 0 10px;
      }
    }
  }

  .dropdown-menu {
    padding-bottom: 0;
    line-height: $line-height-base;
    font-size: 1.6rem;
    -webkit-box-shadow: 0 0 5px 0 rgba(0,0,0,0.17);
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.17);

    .dropdown-item {
      min-width: 180px;
      padding: 0;

      &:active, &.active {
        background-color: #{$tertiary}26;
      }
  
      .nav-link {
        padding: 15px 30px !important;
      }
    }
  }

  .accountBar {
    .dropdown-item {
      padding: 15px 30px !important;

    }
  }
}

.navbar-brand {
  color: $navbar-active-color;

  @include hover-focus {
    color: $navbar-active-color;
  }
}

.navbar-nav {
  .nav-link {
    color: $navbar-color;

    @include hover-focus {
      color: $navbar-hover-color;
    }
  }

  .open > .nav-link,
  .active > .nav-link,
  .nav-link.open,
  .nav-link.active {
    @include plain-hover-focus {
      color: $navbar-active-color;
    }
  }
}

.navbar-divider {
  background-color: rgba(0,0,0,.075);
}

@include media-breakpoint-up(lg) {
  .brand-minimized {
    .app-header.navbar {
      .navbar-brand-group {
        width: $navbar-brand-minimized-width;

      }
    }
  }
}
